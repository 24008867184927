* {
    box-sizing: border-box;
}

body{
    background-color: #111;
    color: #fff;
    margin: 20px;
}

@media (max-width: 760px){
    body{
        margin: 10px;
    }
}